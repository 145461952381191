import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Leaderboard from './components/Leaderboard';
import PlayerStats from './components/PlayerStats';
import MatchReplay from './components/MatchReplay';
import Products from './components/Products';
import OrderConfirmation from './components/OrderConfirmation';
import './App.css';
import { FaDiscord, FaGithub, FaTrophy, FaChartBar, FaPlay, FaShoppingCart, FaMedal, FaStar } from 'react-icons/fa';

const App = () => (
  <Router>
    <div className="App">
      <header className="App-header">
        <h1>AU++</h1>
        <p className="subtitle">The Ultimate Among Us Competitive Platform</p>
        <nav>
          <ul>
            <li><Link to="/"><FaPlay /> Home</Link></li>
            <li><Link to="/leaderboard"><FaTrophy /> Leaderboard</Link></li>
            <li><Link to="/match-replay"><FaChartBar /> Match Replay</Link></li>
            <li><Link to="/products"><FaShoppingCart /> Products</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/player/:season/:playerName" element={<PlayerStats />} />
          <Route path="/match-replay" element={<MatchReplay />} />
          <Route path="/products" element={<Products />} />
          <Route path="/success" element={<OrderConfirmation />} />
        </Routes>
      </main>
    </div>
  </Router>
);

const Home = () => (
  <div className="Home">
    <h1>Among Us World Cup</h1>
    <p className="announcement">Join us for the first-ever Among Us World Cup!</p>
    
    <div className="video-container">
      <iframe
        className="youtube-video"
        src="https://www.youtube.com/embed/vIqUDtN7YwQ"
        title="Among Us World Cup"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>

    <div className="tournament-info">
      <div className="how-to-play">
        <h2>How to Participate</h2>
        <p>
          Join our Discord server to participate in the World Cup! Play Among Us in voice channels 
          where you'll be connected to our bot that tracks your progress and performance. 
          Compete against players worldwide and climb the rankings!
        </p>
      </div>

      <div className="prize-pool">
        <h2>Prize Pool</h2>
        <div className="prizes">
          <div className="main-prizes">
            <div className="prize"><FaTrophy className="gold" /> 1st Place: $800</div>
            <div className="prize"><FaMedal className="silver" /> 2nd Place: $550</div>
            <div className="prize"><FaMedal className="bronze" /> 3rd Place: $400</div>
            <div className="prize"><FaStar /> 4th Place: $250</div>
            <div className="prize"><FaStar /> 5th Place: $100</div>
          </div>
          
          <div className="special-prizes">
            <h3>Special Awards</h3>
            <div className="prize">Best Impostor: $100</div>
            <div className="prize">Best Crewmate: $100</div>
            <div className="prize">Top 3 Rookies: $50 each</div>
          </div>
        </div>
      </div>

      <div className="tournament-dates">
        <h2>Tournament Dates</h2>
        <p>November 30, 2024 - January 26th, 2025</p>
      </div>
    </div>

    <div className="discord-section">
      <FaDiscord className="discord-logo" />
      <h2>Join Our Discord Server</h2>
      <p>Compete in the World Cup and join the largest competitive Among Us community!</p>
      <a 
        href="https://discord.gg/aupp" 
        target="_blank" 
        rel="noopener noreferrer"
        className="discord-button"
      >
        Join Discord
      </a>
    </div>
  </div>
);

export default App;
